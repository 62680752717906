import React from "react"
import styled from "styled-components"
import LinkTo from "./LinkTo"
import Img from "gatsby-image"
import { Link } from "gatsby"

const ItemContainer = styled.div`
    max-width: 620px;
    margin-bottom: 50px;

    @media screen and (max-width: 1000px) {
        margin-left: auto;
        margin-right: auto
    }

    @media screen and (max-width: 750px) {
        margin-bottom: 75px;
    }
`
const ImgTag = styled(Img)`
    width: 100%;
    margin-bottom: 20px;
    max-height: 70vh;

    @media screen and (max-width: 1000px) {
        max-height: 60vh;
    }

    @media screen and (max-width: 900px) {
        max-height: 45vh;
    }
`
const PTag = styled.p`
    margin-top: 15px;
    font-weight: 300;
    letter-spacing: 2px;
    font-size: 16px;
    line-height: 20px;

    @media screen and (min-width: 1600px) {
        font-size: 18px;
    }
`

const SubpageItem = ({ itemURL, photoURL, title, description, divClass }) => (
    <ItemContainer className={divClass}>
        <Link to={itemURL}>
            <ImgTag fluid={photoURL}></ImgTag>
        </Link>
        <LinkTo linkURL={itemURL} linkName={title}></LinkTo>
        <PTag>{description}</PTag>
    </ItemContainer>
)

export default SubpageItem